import React, { forwardRef } from 'react';
import RepetitionCard from './components/RepetitionCard';

const Section3 = forwardRef((props, ref) => {
  return (
    <div ref={ref} className="nextSection text-gray-700 pb-32 relative max-md:h-fit md:w-screen">
        <div className="flex justify-center items-center">
          <h1 className="text-center font-bold max-md:text-3xl md:text-5xl px-2">Sectorlens products</h1> 
        </div>

        <div className="mt-4 text-center max-md:text-2xl md:text-3xl">Where intelligence meets functionality</div>

        <div className="mx-auto flex flex-row mt-8 items-center justify-center max-lg:flex-col px-8">
            <RepetitionCard title="find-your-SOFTWARE" color="text-sky-600" logo={<img src={"/img/logos/find-your-SOFTWARE.png"} className='mx-auto object-contain' />} text="Software selection processes are inherently complex, especially considering that only 20% of German companies have dedicated IT employees. Misjudged software choices incur significant costs for German SMEs, estimated at around 4 million Euros annually. Meet find-your-SOFTWARE: the pioneering solution automates the selection process, offering unprecedented efficiency and accuracy." link="https://find-your-SOFTWARE.de/" />
            <RepetitionCard title="valuai" color="text-sky-600" logo={<img src={"/img/logos/valuai.png"} className='h-16 w-auto object-contain mx-auto' />} text="VALUAI, powered by the Sectorlens Engine, is an AI-based tool for analyzing business partners and competitors. It supports companies in identifying relevant market participants, monitoring their financial and performance figures and making data-based decisions. An integrated news dashboard provides up-to-date information on market changes and industry trends." link="https://valuai.sectorlens.de/" />
            <RepetitionCard title="aisle" color="text-sky-600" logo={<img src={"/img/logos/aisle.png"} className='h-16 mx-auto object-contain ' />} text="AISLE, powered by the Sectorlens Engine, is an AI-based tool for the targeted management of sales activities. Based on a database of more than 2 million companies, AISLE identifies sales leads according to flexibly predefined requirements such as industry, company size or location." link="https://aisle.sectorlens.de/" />
        </div>
        
    </div>
  );
});

export default React.memo(Section3);